<template>
  <InteriorLayout type="list" :onInteriorFilter="onFilter">
    <PaginationLayout :lastPage="lastPage" :onPage="onPage">
      <div
        class="interior-office-list-wrapper grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-rows-2 gap-2 sm:gap-4 lg:gap-8 mt-10"
      >
        <InteriorOfficeItem v-for="item in data" :key="item.id" :data="item" />
      </div>
    </PaginationLayout>
  </InteriorLayout>
</template>

<script>
import InteriorLayout from "@/components/InteriorLayout.vue";
import PaginationLayout from "@/components/PaginationLayout.vue";
import InteriorOfficeItem from "@/components/InteriorOfficeItem.vue";
import services from "@/services";

export default {
  name: "InteriorList",
  components: {
    InteriorLayout,
    PaginationLayout,
    InteriorOfficeItem,
  },
  data() {
    return {
      data: [],
      loading: false,
      lastPage: 1,
      filter: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    onPage: function(value) {
      this.currentPage = value;
      this.getData();
    },
    async getData() {
      const { data, total, lastPage } = await services.interiorOfficeList({
        page: this.currentPage,
        paginate: 8,
        ...this.filter,
      });
      this.data = data;
      this.lastPage = lastPage;
      this.placeTotal = total;
    },
    onFilter(value) {
      this.filter = value;
      this.getData();
    },
  },
  watch: {
    currentPage: function() {
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped></style>
